<template>
    <section>
        <v-text-field
            solo
            dense
            flat
            class="col-xl-3 col-lg-4 custom-border mb-5 general-custom-field f14 poppins fw500"
            hide-details
            prepend-inner-icon="mdi-magnify"
            placeholder="Search user"
            v-model="search"
        />
        <v-data-table
            v-if="!loading"
            :headers="headers"
            :items="enroll_students"
            class="text--center custom-border poppins"
            :search="search">
            <template v-slot:item.user="{ item }">
                <span>
                    {{ item.first_name ? item.first_name : 'User' }} {{ item.last_name ? item.last_name : 'Account' }}
                </span>
            </template>
            <template v-slot:item.enrollment_status="{ item }">
                <v-alert v-if="item.status === 'enrolled'" dense color="#7BC14533" class="success--text ma-auto">ENROLLED</v-alert>
                <v-alert v-if="item.status === 'pending'" dense color="#BDBDBD33" class="secondary-2--text ma-auto">PENDING</v-alert>
                <v-alert v-if="item.status === 'disqualified'" dense color="#E49E3233" class="danger--text ma-auto">DISQUALIFIED</v-alert>
            </template>
            <template v-slot:item.date_enrolled="{ item }">
                <span>
                    {{ $dateFormat.mmDDyy(item.created_at) }}
                </span>
            </template>
            <template v-slot:item.action="{ item }">
                <v-btn
                text
                class="primary--text text-capitalize f14 roboto"
                @click="getUserEnrollee(item)"
                >
                <v-icon left>mdi-eye-outline</v-icon>
                View
                </v-btn>
            </template>
        </v-data-table>
        <circular v-else />
        <ViewEnrollee :dialog="dialog" :user="user" @close="dialog = false"/>
    </section>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import headers from '../../constants/tblheaders/enrollment/enrollment'
import ViewEnrollee from '../../components/teacher/enrollment/ViewEnrollee.vue'

export default {
    data: () => ({
        headers,
        loading: false,
        dialog: false,
        user: null,
        search: ''
    }),
    components: {
        ViewEnrollee
    },
    computed: {
        ...mapState('instructor', {
            enroll_students: (state) => state.enroll_students
        })
    },
    methods: {
        ...mapActions('instructor', ['getEnrollStudentAction']),
        getUserEnrollee(user){
            this.$api.get(`/instructor/manual/enrollment/user/${user.uuid}`).then((res) => {
                this.user = res.data.data
                this.dialog = true
            })
        }
    },
    created() {
        this.loading = true
        this.getEnrollStudentAction().then(() => {
            this.loading = false
        })
    }
}
</script>