<template>
    <v-dialog persistent v-model="dialog" max-width="400">
        <v-dialog
            v-model="fullDialog"
            fullscreen
            overlay
            >
            <v-container class="fill-height" @click="closeViewFull" >
                <v-btn color="secondary" icon absolute top right class="mt-3" @click="closeViewFull" dense>
                    <v-icon large>mdi-close-thick</v-icon>
                </v-btn>
                <v-img 
                    :src="selectedImage"
                    class="fill-height"
                />
            </v-container>
        </v-dialog>
        <v-card class="pb-5"  v-if="user">
            <v-card-title class="d-flex flex-row">
                <v-row no-gutters>
                    <v-col>
                        <section>
                            <span class="poppins f15 fw600 primary--text mr-1">Enrollment: </span>
                            <span class="poppins f15 fw600">
                                {{ user.first_name ? user.first_name : 'Learner' }}
                                {{ $userFormat.middle_initial(user.middle_name) }}
                                {{ user.last_name ? user.last_name : 'Account' }}
                                {{ user.suffix ? user.suffix : '' }}
                            </span>
                        </section>
                    </v-col>
                    <v-col cols="1">
                        <v-btn icon dense @click="$emit('close')">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-sheet max-height="450" class="overflow-y-auto">
                <v-card outlined v-for="invoice in user.invoices" :key="invoice.id" class="pa-5 ma-5 d-flex flex-column">
                    <section>
                        <v-chip
                            v-if="invoice.status === 1"
                            class="float-right mb-3 fw500"
                            color="green lighten-5"
                            text-color="green"
                            label
                        >
                            APPROVED
                        </v-chip>
                        <v-chip
                            v-if="invoice.status === 2"
                            class="float-right mb-3 fw500"
                            color="grey lighten-4"
                            text-color="grey"
                            label
                        >
                            PENDING
                        </v-chip>
                        <v-chip
                            v-if="invoice.status === 3"
                            class="float-right mb-3 fw500"
                            color="red lighten-4"
                            text-color="red"
                            label
                        >
                            DISQUALIFIED
                        </v-chip>
                    </section>
                    <v-row>
                        <v-col cols="12">
                            <label class="caption">COURSE</label>
                            <div class="fw500 primary--text roboto f14"> {{ invoice.course.title }}</div>
                        </v-col>
                        <v-col cols="12">
                            <label class="caption">ENROLLED BY</label>
                            <div class="fw500 roboto f14" v-if="invoice.enroll_by">{{ invoice.enroll_by.first_name && invoice.enroll_by.first_name }} {{ invoice.enroll_by.last_name && invoice.enroll_by.last_name }}</div>
                        </v-col>
                        <v-col cols="12">
                            <label class="caption">PROOF OF PAYMENT</label>
                            <div class="fw500 poppins f10" v-if="invoice.image">
                                <v-avatar
                                    size="125"
                                    tile
                                    class="cursor-pointer"
                                    @click="showImage(invoice.image.url)"
                                >
                                    <v-img :src="invoice.image.url"></v-img>
                                </v-avatar>
                            </div>
                            <div v-else class="f12 secondary-2--text"><i>No proof of payment available</i></div>
                        </v-col>
                        <v-col cols="12">
                            <label class="caption">OR NUMBER</label>
                            <div class="fw500 roboto f14"> {{ invoice.official_receipt_no }}</div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-sheet>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ['dialog','user'],
    data: () => ({
        items: [
            { text: 'APPROVED', value: 1 },
            { text: 'PENDING', value: 2 },
            { text: 'DISQUALIFIED', value: 3 },
        ],
        selectedImage: null,
        fullDialog: false
    }),
    methods: {
        showImage(image) {
            this.selectedImage = image
            this.fullDialog = true
        },
        closeViewFull () {
            this.fullDialog = false
        },
    }
}
</script>